import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/react/raul/raulPortfolio/src/components/layoutmdx.js";
import "./cssflexbox.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Flexbox`}</h1>
    <h2>{`display: flex`}</h2>
    <p>{`El elemento que se aplica flex adopta propiedades de flex-container y a sus hijos directos se les aplicará las propiedades de flex-item.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="flexcontainer">
  Container
  <div class="boxflex">Item</div>
  <div class="boxflex">Item</div>
  <div class="boxflex">Item</div>
  <div class="boxflex">Item</div>
  <div class="boxflex">Item</div>
</div>
`}</code></pre>
    <div className="flexcontainer">
  Container
  <div className="boxflex">Item</div>
  <div className="boxflex">Item</div>
  <div className="boxflex">Item</div>
  <div className="boxflex">Item</div>
  <div className="boxflex">Item</div>
    </div>
    <h2>{`flex-direction`}</h2>
    <h3>{`row (default) | row-reverse | column | column-reverse`}</h3>
    <blockquote>
      <p parentName="blockquote">{`row: organiza los items en líneas.
column: organiza los items en columnas.`}</p>
    </blockquote>
    <h4>{`flex-direction: row`}</h4>
    <div className="flexcontainer" style={{
      "flexDirection": "row"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
  <div className="boxflex">4</div>
  <div className="boxflex">5</div>
    </div>
    <h4>{`flex-direction: row-reverse`}</h4>
    <div className="flexcontainer" style={{
      "flexDirection": "row-reverse"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
  <div className="boxflex">4</div>
  <div className="boxflex">5</div>
    </div>
    <h4>{`flex-direction: column`}</h4>
    <div className="flexcontainer" style={{
      "flexDirection": "column"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h4>{`flex-direction: column-reverse`}</h4>
    <div className="flexcontainer" style={{
      "flexDirection": "column-reverse"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h2>{`flex-grow: `}<em parentName="h2">{`number`}</em></h2>
    <p>{`El valor por defecto 0 asigna el mismo espacio para todos los
items, cuando asignamos otro valor mayor que cero se asigna más espacio dinámico
para el item.`}</p>
    <div className="flexcontainer">
  <div className="boxflex" style={{
        "flexGrow": "0"
      }}>
    1
  </div>
  <div className="boxflex" style={{
        "flexGrow": "1"
      }}>
    2
  </div>
  <div className="boxflex" style={{
        "flexGrow": "2"
      }}>
    3
  </div>
    </div>
    <h2>{`flex-wrap`}</h2>
    <h3>{`no-wrap (default) | wrap | wrap-reverse`}</h3>
    <ul>
      <li parentName="ul">{`no-wrap: modifica el tamaño de los items para mantenerlos en la misma línea.`}</li>
      <li parentName="ul">{`wrap: respeta el tamaño de cada item y cuando no cabe en la misma línea lo envía a la línea de abajo.`}</li>
      <li parentName="ul">{`wrap-reverse: lo mismo que wrap pero envía el item en la línea de arriba. no-wrap`}</li>
    </ul>
    <div className="flexcontainer" style={{
      "flexWrap": "no-wrap"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
  <div className="boxflex">4</div>
  <div className="boxflex">5</div>
  <div className="boxflex">6</div>
  <div className="boxflex">7</div>
    </div>
    <h4>{`wrap`}</h4>
    <div className="flexcontainer" style={{
      "flexWrap": "wrap"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
  <div className="boxflex">4</div>
  <div className="boxflex">5</div>
  <div className="boxflex">6</div>
  <div className="boxflex">7</div>
    </div>
    <h4>{`wrap-reverse`}</h4>
    <div className="flexcontainer" style={{
      "flexWrap": "wrap-reverse"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
  <div className="boxflex">4</div>
  <div className="boxflex">5</div>
  <div className="boxflex">6</div>
  <div className="boxflex">7</div>
    </div>
    <h2>{`flex-flow`}</h2>
    <h3>{`row nowrap (default) | column wrap`}</h3>
    <p>{`Se trata de un shorthand para cambiar los dos valores de flex-direction y flex-wrap con una única propiedad.`}</p>
    <h2>{`justify-content`}</h2>
    <h3>{`flex-start (default)| flex-end | center | space-between | space-around | space-evenly`}</h3>
    <ul>
      <li parentName="ul">{`flex-start: posiciona en el principio del main axis.`}</li>
      <li parentName="ul">{`flex-end: posiciona en el final del main axis.`}</li>
      <li parentName="ul">{`center: centra los items.`}</li>
      <li parentName="ul">{`space-between: separa lo máximo posible entre items.`}</li>
      <li parentName="ul">{`space-around: separa con el mismo margen entre items.`}</li>
      <li parentName="ul">{`space-evenly: mismo espacio entre items y los extremos.`}</li>
    </ul>
    <h4>{`justify-content: flex-start`}</h4>
    <div className="flexcontainer" style={{
      "justifyContent": "flex-start"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h4>{`justify-contet: flex-end`}</h4>
    <div className="flexcontainer" style={{
      "justifyContent": "flex-end"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h4>{`justify-contet: center`}</h4>
    <div className="flexcontainer" style={{
      "justifyContent": "center"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h4>{`justify-content: space-between`}</h4>
    <div className="flexcontainer" style={{
      "justifyContent": "space-between"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h4>{`justify-conent: space-around`}</h4>
    <div className="flexcontainer" style={{
      "justifyContent": "space-around"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h4>{`justify-content: space-evenly`}</h4>
    <div className="flexcontainer" style={{
      "justifyContent": "space-evenly"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
    </div>
    <h2>{`Align-items`}</h2>
    <h3>{`flex-start | flex-end | center | stretch | baseline`}</h3>
    <ul>
      <li parentName="ul">{`flex-start: fija los items en el inicio del cross axis.`}</li>
      <li parentName="ul">{`flex-end: fija los items en el final del cross axis.`}</li>
      <li parentName="ul">{`center: centra los items verticalmente.`}</li>
      <li parentName="ul">{`stretch (default): ocupa todo el alto del contenedor.`}</li>
      <li parentName="ul">{`baseline:`}</li>
    </ul>
    <h4>{`align-items: flex-start`}</h4>
    <div className="flexcontainer" style={{
      "alignItems": "flex-start",
      "height": "300px"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
  <div className="boxflex">4</div>
  <div className="boxflex">5</div>
    </div>
    <p>{`####align-items:flex-end`}</p>
    <div className="flexcontainer" style={{
      "alignItems": "flex-end",
      "height": "300px"
    }}>
  <div className="boxflex">1</div>
  <div className="boxflex">2</div>
  <div className="boxflex">3</div>
  <div className="boxflex">4</div>
  <div className="boxflex">5</div>
    </div>
    <h4>{`align-items:center`}</h4>
    <div className="flexcontainer" style={{
      "alignItems": "center",
      "height": "300px"
    }}>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth">Flexbox Flexbox Flexbox Flexbox</div>
  <div className="boxflexnowidth">
    Flexbox Flexbox Flexbox Flexbox Flexbox Flexbox Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox Flexbox Flexbox Flexbox</div>
  <div className="boxflexnowidth">FFlexbox Flexbox</div>
    </div>
    <p>{`Centra el punto medio de cada item.`}</p>
    <div className="flexcontainer" style={{
      "alignItems": "center",
      "height": "150px"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "40px"
      }}>
    1
  </div>
  <div className="boxflexnowidth">2</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "10px"
      }}>
    3
  </div>
  <div className="boxflexnowidth">4</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "25px"
      }}>
    5
  </div>
    </div>
    <h4>{`align-items: stretch para que funcione no debe tener ningún valor o auto en height.`}</h4>
    <div className="flexcontainer" style={{
      "alignItems": "stretch",
      "height": "300px"
    }}>
  <div className="boxflex" style={{
        "height": "auto"
      }}>
    1
  </div>
  <div className="boxflex" style={{
        "height": "auto"
      }}>
    2
  </div>
  <div className="boxflex" style={{
        "height": "auto"
      }}>
    3
  </div>
  <div className="boxflex" style={{
        "height": "auto"
      }}>
    4
  </div>
  <div className="boxflex" style={{
        "height": "auto"
      }}>
    5
  </div>
    </div>
    <h4>{`align-items: baseline El contenido se encuentra centrado en la misma línea.`}</h4>
    <div className="flexcontainer" style={{
      "alignItems": "baseline",
      "height": "150px"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "40px"
      }}>
    1
  </div>
  <div className="boxflexnowidth">2</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "10px"
      }}>
    3
  </div>
  <div className="boxflexnowidth">4</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "25px"
      }}>
    5
  </div>
    </div>
    <h2>{`align-content`}</h2>
    <h3>{`flex-start | flex-end| center | stretch (default)| space-between |space-around | space-evenly`}</h3>
    <p>{`Trabaja igual que align-items pero se aplica cuando hay más de una fila.`}</p>
    <h4>{`align-content: flex-start`}</h4>
    <div className="flexcontainer" style={{
      "alignContent": "flex-start",
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
    </div>
    <h4>{`align-content: flex-end`}</h4>
    <div className="flexcontainer" style={{
      "alignContent": "flex-end",
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
    </div>
    <h4>{`align-content: center`}</h4>
    <div className="flexcontainer" style={{
      "alignContent": "center",
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
    </div>
    <h4>{`align-content: stretch`}</h4>
    <div className="flexcontainer" style={{
      "alignContent": "stretch",
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
    </div>
    <h4>{`align-content: space-between`}</h4>
    <div className="flexcontainer" style={{
      "alignContent": "space-between",
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
    </div>
    <h4>{`align-content: space-around`}</h4>
    <div className="flexcontainer" style={{
      "alignContent": "space-around",
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
    </div>
    <h4>{`align-content: space-evenly`}</h4>
    <div className="flexcontainer" style={{
      "alignContent": "space-evenly",
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
  <div className="boxflexnowidth">Flexbox</div>
  <div className="boxflexnowidth" style={{
        "fontSize": "20px"
      }}>
    Flexbox
  </div>
    </div>
    <h2>{`align-self`}</h2>
    <h3>{`auto | flex-start | flex-end | center | baseline | stretch`}</h3>
    <div className="flexcontainer" style={{
      "height": "300px",
      "flexWrap": "wrap"
    }}>
  <div className="boxflexnowidth" style={{
        "alignSelf": "auto"
      }}>
    auto
  </div>
  <div className="boxflexnowidth" style={{
        "alignSelf": "flex-start",
        "fontSize": "25px"
      }}>
    flex-start
  </div>
  <div className="boxflexnowidth" style={{
        "alignSelf": "flex-end"
      }}>
    flex-end
  </div>
  <div className="boxflexnowidth" style={{
        "alignSelf": "center"
      }}>
    center
  </div>
  <div className="boxflexnowidth" style={{
        "alignSelf": "baseline"
      }}>
    baseline
  </div>
  <div className="boxflexnowidth" style={{
        "alignSelf": "stretch"
      }}>
    stretch
  </div>
    </div>
    <h2>{`flex`}</h2>
    <h3>{`grow & shrink & basis`}</h3>
    <p>{`0 1 auto (default)`}</p>
    <ul>
      <li parentName="ul">{`grow: distribuye el espacio sobrante entre los items.`}</li>
      <li parentName="ul">{`basis: asigna el espacio al item dependiendo de la dirección. (row - width) (column - height)`}</li>
      <li parentName="ul">{`shrink: encoge el item tantas veces como el número asignado cuando el espacio en el viewport es más pequeño al necesario.
`}{`*`}{`Para ver el efecto hay que modificar el tamaño de la ventana.`}</li>
    </ul>
    <div className="flexcontainer" style={{}}>
  <div className="boxflexnowidth" style={{
        "flex": "0 1 auto"
      }}>
    flex: 0 1 auto
  </div>
  <div className="boxflexnowidth" style={{
        "flex": "1 1 auto"
      }}>
    flex: 1 1 auto
  </div>
  <div className="boxflexnowidth" style={{
        "flex": "0 1 auto"
      }}>
    flex: 0 1 auto
  </div>
    </div>
    <div className="flexcontainer" style={{}}>
  <div className="boxflexnowidth" style={{
        "flex": "0 1 200px"
      }}>
    flex: 0 1 200px
  </div>
  <div className="boxflexnowidth" style={{
        "flex": "2 10 200px"
      }}>
    flex: 2 10 200px
  </div>
  <div className="boxflexnowidth" style={{
        "flex": "0 1 200px"
      }}>
    flex: 0 1 200px
  </div>
    </div>
    <div className="flexcontainer" style={{}}>
  <div className="boxflexnowidth" style={{
        "flex": "1 5 200px"
      }}>
    flex: 1 5 200px
  </div>
  <div className="boxflexnowidth" style={{
        "flex": "1 1 200px"
      }}>
    flex: 1 1 200px
  </div>
  <div className="boxflexnowidth" style={{
        "flex": "1 5 200px"
      }}>
    flex: 1 5 200px
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      